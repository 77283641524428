
import { defineComponent, ref, computed, watch } from 'vue';
import { community } from '@/data';
import { dayjsFormat } from '@/methods/dayjs';
import Big from 'big.js';
import dayjs from 'dayjs';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    name: 'property-bread-crumb',
    props: {
        UUID: {
            type: String
        },
        sendStatus: {
            type: Number,
            default: 1
        },
        communalFee: {
            type: Number,
            default: 0
        }
    },
    setup(props, { emit }) {
        const dueDate = community.communalDueDate;
        const isSuccessDialog = ref(false);
        const formData = ref({
            EffectDate: new Date(),
            RenewMonth: 0
        });
        // 监听开始时间变化，当开始时间等于dueDate时，起始月份为1
        watch(() => formData.value.EffectDate, (val) => {
            formData.value.RenewMonth = val.getDate() === dueDate.value ? 1 : 0;
        }, { immediate: true });

        function startDateOption(time: Date) {
            const today = new Date();
            // 获取下个DeuDate所在月份
            const nextDeuDateMonth = new Date().getDate() >= dueDate.value
                ? new Date(today.getFullYear(), today.getMonth() + 1, 1) : new Date(today.getFullYear(), today.getMonth(), 1);
            const nextDueDate = new Date(nextDeuDateMonth.getFullYear(), nextDeuDateMonth.getMonth(), dueDate.value);
            // 禁止选择当天之前的日期以及下个DeuDate之后的日期
            return time.getTime() <= new Date().getTime() - 86400000 || time.getTime() >= nextDueDate.getTime() + 86400000;
        }

        // 获取月份天数
        function getDaysOfMonth(date: Date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            return new Date(year, month + 1, 0).getDate();
        }
        // 所选startDate到DueDate的时间, 可能下个dueDate在当月,或者下个月
        const remainingDays = computed(() => {
            if (formData.value.EffectDate.getDate() > dueDate.value) {
                // 获取本月最后一天的日期
                const lastDayOfMonth = getDaysOfMonth(formData.value.EffectDate);
                return lastDayOfMonth - formData.value.EffectDate.getDate() + dueDate.value;
            }
            if (formData.value.EffectDate.getDate() === dueDate.value) {
                return 0;
            }
            return dueDate.value - formData.value.EffectDate.getDate();
        });

        // 下次过期时间
        const expirationDate = computed(() => {
            const expirationDueDate = dayjs(formData.value.EffectDate);
            return expirationDueDate.add(remainingDays.value, 'day').add(formData.value.RenewMonth, 'month').format('MMMM DD, YYYY');
        });

        // 所选start到下个 DueDate的日期
        const nextMonthDueDate = computed(() => {
            const nextMonthDate = new Date(formData.value.EffectDate.getTime());
            nextMonthDate.setDate(formData.value.EffectDate.getDate() + remainingDays.value);
            return nextMonthDate;
        });

        // 当月剩下时间
        const currentRemainDate = computed(() => formData.value.EffectDate.getDate() > dueDate.value ? remainingDays.value - dueDate.value + 1 : remainingDays.value);
        // CommunalFee计算金额，保留两位小数
        const allCommunalFee = computed(() => {
            const oneBig = Big(formData.value.RenewMonth).times(props.communalFee);
            const twoBig = Big(currentRemainDate.value).times(props.communalFee).div(getDaysOfMonth(formData.value.EffectDate));
            if (dueDate.value !== 1 && formData.value.EffectDate.getDate() > dueDate.value) {
                const threeBig = Big(dueDate.value - 1).times(props.communalFee).div(getDaysOfMonth(nextMonthDueDate.value));
                return oneBig.plus(twoBig).plus(threeBig).toFixed(2);
            }
            return oneBig.plus(twoBig).toFixed(2);
        });
        function sendClickPaysApi() {
            const params = {
                UUID: props.UUID,
                EffectDate: dayjsFormat(formData.value.EffectDate, 'YYYY-MM-DD'),
                RenewMonth: formData.value.RenewMonth
            };
            HttpRequest.post('v3/web/community/communalFee/sendClickPays', params, [() => {
                isSuccessDialog.value = true;
            }, false]);
        }
        function close() {
            emit('close');
        }
        return {
            isSuccessDialog,
            dueDate,
            formData,
            remainingDays,
            nextMonthDueDate,
            currentRemainDate,
            allCommunalFee,
            expirationDate,
            getDaysOfMonth,
            startDateOption,
            sendClickPaysApi,
            close
        };
    }
});
