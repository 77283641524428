
import {
    defineComponent, PropType, Ref, ref
} from 'vue';
import apartmentEditDialog, { ApartmentForm } from '@/components/view/pm/apartment-edit-dialog';
import apartmentClickpayDialog from '@/components/view/pm/apartment-clickpay-dialog';
import paymentRecordsDialog from '@/components/view/pm/payment-records-dialog';
import { filterEmptyCellValue } from '@/components/common/list';
import svgIcon from '@/components/common/svg-icon';
import HttpRequest from '@/util/axios.config';
import { ListData } from './all.type';

export default defineComponent({
    components: {
        apartmentEditDialog,
        svgIcon,
        apartmentClickpayDialog,
        paymentRecordsDialog
},
    props: {
        basicInformationData: {
            type: Array as PropType<Array<ListData>>,
            required: true
        },
        basicInformationEditForm: {
            type: Object as PropType<ApartmentForm>,
            required: true
        }
    },
    setup(props, { emit }) {
        const isShowApartmentEditDialog = ref(false);
        // 显示未支付弹窗
        const isShowClickpayDialog = ref(false);
        // 显示发送记录弹窗
        const isShowPaymentRecords = ref(false);
        const initForm: Ref<ApartmentForm> = ref({
            ID: '',
            AptName: '',
            CallType: 0,
            EnableIpDirect: 0,
            TempKeyPermission: 0,
            LimitCreateQRcode: 0,
            LimitFamilyMember: 0,
            FamilyMemberControl: 0,
            AllowCreateSlaveCnt: '',
            RegisterFaceControl: 1,
            LimitRegisterFace: 0,
            Area: null,
            CommunalFee: 1,
            AutoSendBills: 1,
            IsDefaultCommunalFee: 1
        });

        const edit = () => {
            isShowApartmentEditDialog.value = true;
            initForm.value.ID = props.basicInformationEditForm.ID;
            initForm.value.AptName = props.basicInformationEditForm.AptName;
            initForm.value.CallType = props.basicInformationEditForm.CallType;
            initForm.value.EnableIpDirect = props.basicInformationEditForm.EnableIpDirect;
            initForm.value.TempKeyPermission = props.basicInformationEditForm.TempKeyPermission;
            initForm.value.LimitCreateQRcode = props.basicInformationEditForm.LimitCreateQRcode;
            initForm.value.LimitFamilyMember = props.basicInformationEditForm.LimitFamilyMember;
            initForm.value.FamilyMemberControl = props.basicInformationEditForm.FamilyMemberControl;
            initForm.value.AllowCreateSlaveCnt = props.basicInformationEditForm.AllowCreateSlaveCnt;
            initForm.value.RegisterFaceControl = props.basicInformationEditForm.RegisterFaceControl;
            initForm.value.LimitRegisterFace = props.basicInformationEditForm.LimitRegisterFace;
            initForm.value.Area = props.basicInformationEditForm.Area;
            initForm.value.CommunalFee = props.basicInformationEditForm.CommunalFee;
            initForm.value.AutoSendBills = props.basicInformationEditForm.AutoSendBills;
            initForm.value.IsDefaultCommunalFee = props.basicInformationEditForm.IsDefaultCommunalFee;
        };

        const success = () => {
            emit('updateBasicInformation');
            isShowApartmentEditDialog.value = false;
        };

        const sendStatus = ref(1);
        // 给主账号发送邮件、消息
        function sendClickPaysApi() {
            HttpRequest.post('v3/web/community/communalFee/checkSendClickPays', { UUID: props.basicInformationEditForm.UUID }, (res: {data:{EnableSendClickPays: number}}) => {
                sendStatus.value = res.data.EnableSendClickPays;
                isShowClickpayDialog.value = true;
            });
        }
        return {
            isShowApartmentEditDialog,
            isShowClickpayDialog,
            isShowPaymentRecords,
            initForm,
            edit,
            success,
            filterEmptyCellValue,
            sendClickPaysApi,
            sendStatus
        };
    }
});
